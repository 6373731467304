import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3333";
export const uploadProfileImage = async (file) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("profile_image", file);

  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/upload-profile-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading profile image", error);
    throw error;
  }
};
export const userData = async () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};
export const getImage = ({ image }) => {
  try {
    if (!image) {
      throw new Error("Image parameter is required");
    }
    return `${API_BASE_URL}/${image}`;
  } catch (error) {
    console.error("Error getting image", error);
    throw error;
  }
};
export const checkEmailOrMobile = async ({ email, mobileNumber }) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/check-email-or-mobile`,
      {
        email,
        mobile_number: mobileNumber,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking email or mobile number", error);
    throw error;
  }
};

export default { uploadProfileImage, checkEmailOrMobile };
