import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../services/authService";
import "../css/login.css"; // Your CSS file
import "../css/common-style.css";
import Verification from "../components/Verification";
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid");
      valid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    }

    return valid;
  };
  useEffect(() => {
    const isVerified = localStorage.getItem("isVerified");
    if (isVerified === "true") {
      setIsVerified(true);
    }
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to Dashboard if token exists
      navigate("/dashboard");
    }
  }, [navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await login(email, password);
      if (response.status) {
        if (response.token) {
          localStorage.setItem("token", response.token.token);
          localStorage.setItem("user", JSON.stringify(response.user));
          navigate("/dashboard"); // Redirect to the dashboard or home page
        } else {
          alert(response.message); // Show the error message from AdonisJS
        }
      } else {
        if (response.message === "OTP sent") {
          localStorage.setItem("isVerified", "true");
          localStorage.setItem("email", email);
          setIsVerified(true);
        } else {
          alert(response.message);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert(error.message);
    } finally {
      setIsSubmitting(false); // Optionally re-enable the button after the process
    }
  };

  return (
    <main>
      <div className="wrapper">
        <section className="custSection">
          <div className="logoContainer">
            <img
              src="/assets/login/login-main-logo.png" // Adjust the path as needed
              alt="login-main-logo"
              className="loginMainLogo"
            />
          </div>
          {!isVerified ? (
            <>
              <div>
                <h2 className="text-center">Login</h2>
              </div>
              <form className="container" onSubmit={handleSubmit}>
                <div className="text-center">
                  <input
                    className="loginInput"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && <p className="error">{emailError}</p>}
                </div>
                <div className="text-center">
                  <input
                    className="loginInput"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && <p className="error">{passwordError}</p>}
                </div>
                <div className="link-container">
                  <Link to="/register" className="link">
                    Sign Up
                  </Link>
                  <span className="separator">/</span>
                  <Link to="/forgot-password" className="link">
                    Forgot Password?
                  </Link>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="loginBtn signIn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Sign In"}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <Verification />
          )}
        </section>
        <footer className="loginFooter footerStyle">
          <div>
            <p className="text-center text-white font-12 weight-400">
              Skillpundit Career Private Limited
            </p>
          </div>
        </footer>
        <figure className="topEffect">
          <img src="/assets/login/topEffect.svg" alt="topEffect" />
        </figure>
        <figure className="bottomEffect">
          <img src="/assets/login/bottomEffect.svg" alt="bottomEffect" />
        </figure>
      </div>
    </main>
  );
};

export default LoginPage;
