// src/services/authService.js

import axios from "axios";
import axiosInstance from "../axiosInstance";
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3333";

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/register`, userData);
    return response.data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};
export const verifyOtp = async (otp, email) => {
  return axios.post(`${API_BASE_URL}/api/verify-otp`, { otp, email });
};
export const resendOtp = async (email) => {
  return axios.post(`${API_BASE_URL}/api/resend-otp`, { email });
};
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
export const logout = async () => {
  try {
    await axiosInstance.get("/api/logout");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return true;
  } catch (error) {
    console.error("Error verifying token:", error);
    return false;
  }
};
