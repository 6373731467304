import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/common-style.css";
import "../css/login-reg.css";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to Dashboard if token exists
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <main>
      <div className="wrapper">
        <section className="custSection">
          <div className="loginGirlBg">
            <img
              src="../assets/login-reg/login-girl.svg"
              className="loginGirl"
              alt="login-girl"
            />
          </div>
          <div className="container tabCenter">
            <img
              src="../assets/login-reg/login-logo.svg"
              className="loginRegLogo"
              alt="login-logo"
            />
          </div>
          <div className="container" style={{ marginBottom: "50px" }}>
            <h1 className="text-center loginHeading font-24">
              <span className="lh-40">Upgrade your</span>
              <br />
              Communication and
              <br />
              Learning Skills
            </h1>
            <div className="text-center btnBox">
              <Link to="/login" className="text-white">
                <button className="loginBtn signIn">Sign In</button>
              </Link>
            </div>
            <div className="text-center btnBox">
              <Link to="/register" className="text-white">
                <button className="loginBtn newAcc" style={{ margin: "0px" }}>
                  New Account
                </button>
              </Link>
            </div>
          </div>
        </section>
        <footer className="loginFooter footerStyle">
          <div>
            <p className="text-center text-white font-12 weight-400">
              Skillpundit Career Private Limited
            </p>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default HomePage;
