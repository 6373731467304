// ForgotPassword.js
import { useState } from "react";
import { sendPasswordResetLink } from "../services/resetService";
import "../css/login.css"; // Your CSS file
import "../css/common-style.css";
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(email)) {
      setMessage("Please enter a valid email address");
      return;
    }
    try {
      await sendPasswordResetLink(email);
      setMessage("Password reset link sent to your email");
    } catch (error) {
      setMessage("Error sending reset link");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main>
      <div className="wrapper">
        <section className="custSection">
          <div className="logoContainer">
            <img
              src="/assets/login/login-main-logo.png" // Adjust the path as needed
              alt="login-main-logo"
              className="loginMainLogo"
            />
          </div>
          <div>
            <h2 className="text-center">Forgot Password</h2>
          </div>
          {message && <p className="text-center">{message}</p>}
          <form className="container" onSubmit={handleSubmit}>
            <div className="text-center">
              <input
                type="email"
                required
                className="loginInput"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="container">
              <div className="text-center btnBox">
                <button
                  type="submit"
                  className="loginBtn signIn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </section>
        <footer className="loginFooter footerStyle">
          <div>
            <p className="text-center text-white font-12 weight-400">
              Skillpundit Career Private Limited
            </p>
          </div>
        </footer>
        <figure className="topEffect">
          <img src="/assets/login/topEffect.svg" alt="topEffect" />
        </figure>
        <figure className="bottomEffect">
          <img src="/assets/login/bottomEffect.svg" alt="bottomEffect" />
        </figure>
      </div>
    </main>
  );
}

export default ForgotPassword;
