import React from "react";
import "../css/index.css";

const LatestCompetitions = () => (
  <section className="latestCompetitions">
    <div className="bgBox">
      <div className="container d-flex latestMain align-items-center justify-content-between">
        <h2 className="font-14 weight-500">Latest Competitions</h2>
        <button className="viewMore">View More</button>
      </div>
    </div>

    <div className="latestCompProd container">
      <div className="col-1">
        <img
          src="../assets/cup-icon.svg"
          className="round-icon"
          alt="cup-icon"
        />
      </div>
      <div className="col-2">
        <h4 className="font-12">States and their Capitals</h4>
        <p className="font-10">
          Category: <b>General Knowledge</b>
        </p>
        <p className="font-10">
          Age: <b>Above 10 Years</b>
        </p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p>$50</p>
          <button className="buyBtn">Buy</button>
        </div>
      </div>
    </div>
    <div className="latestCompProd container">
      <div className="col-1">
        <img
          src="../assets/cup-icon.svg"
          className="round-icon"
          alt="cup-icon"
        />
      </div>
      <div className="col-2">
        <h4 className="font-12">Vegetables</h4>
        <p className="font-10">
          Category: <b>Vegetables</b>
        </p>
        <p className="font-10">
          Age: <b>Above 10 Years</b>
        </p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p>$50</p>
          <button className="buyBtn">Buy</button>
        </div>
      </div>
    </div>
  </section>
);

export default LatestCompetitions;
