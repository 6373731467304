// ResetPassword.js
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../services/resetService";
import "../css/login.css"; // Your CSS file
import "../css/common-style.css";
function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await resetPassword(token, password);
      navigate("/login");
    } catch (error) {
      setMessage("Error resetting password");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        setPassword(value);
        handlePasswordChange(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        handleConfirmPasswordChange(value);
        break;
      default:
        break;
    }
  };
  const handlePasswordChange = (value) => {
    const passwordErrors = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: passwordErrors }));
  };
  const handleConfirmPasswordChange = (value) => {
    if (value !== password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };
  const validatePassword = (password) => {
    const passwordErrors = [];
    if (password.length < 8) {
      passwordErrors.push("Password must be at least 8 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one uppercase letter."
      );
    }
    if (!/[a-z]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one lowercase letter."
      );
    }
    if (!/[0-9]/.test(password)) {
      passwordErrors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one special character."
      );
    }
    return passwordErrors;
  };
  const validateForm = () => {
    let valid = true;
    let newErrors = {
      password: "",
      confirmPassword: "",
    };
    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      newErrors.password = passwordErrors;
      valid = false;
    }
    if (password !== confirmPassword) {
      newErrors.mobileNumber = "Passwords do not match.";
      valid = false;
    }
    setErrors(newErrors);
    // Additional validation logic can be added here
    return valid;
  };
  return (
    <main>
      <div className="wrapper">
        <section className="custSection">
          <div className="logoContainer">
            <img
              src="/assets/login/login-main-logo.png" // Adjust the path as needed
              alt="login-main-logo"
              className="loginMainLogo"
            />
          </div>
          <div>
            <h2 className="text-center">Reset Password</h2>
          </div>
          {message && <p className="text-center">{message}</p>}
          <form className="container" onSubmit={handleSubmit}>
            <div className="text-center">
              <input
                type="password"
                required
                className="loginInput"
                name="password"
                placeholder="Password"
                value={password}
                onChange={handleChange}
              />
              {errors.password.length > 0 && (
                <ul className="error-message-ul">
                  {errors.password.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              )}
            </div>
            <div className="text-center">
              <input
                type="password"
                className="loginInput"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.confirmPassword && (
                <p className="error">{errors.confirmPassword}</p>
              )}
            </div>
            <div className="container">
              <div className="text-center btnBox">
                <button type="submit" className="loginBtn signIn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </section>
        <footer className="loginFooter footerStyle">
          <div>
            <p className="text-center text-white font-12 weight-400">
              Skillpundit Career Private Limited
            </p>
          </div>
        </footer>
        <figure className="topEffect">
          <img src="/assets/login/topEffect.svg" alt="topEffect" />
        </figure>
        <figure className="bottomEffect">
          <img src="/assets/login/bottomEffect.svg" alt="bottomEffect" />
        </figure>
      </div>
    </main>
  );
}

export default ResetPassword;
