import React from "react";
import "../css/index.css";

const TestSkills = () => (
  <section className="textSkils">
    <div className="testSkillsbox">
      <h2 className="text-center text-uppercase font-14 text-white weight-500">
        Test your skill & earn points
      </h2>
    </div>
    <div className="d-flex justify-content-evenly featureBox">
      <div>
        <a href="../kids/kids.html">
          <img
            src="../assets/kids-icon.svg"
            className="testImg"
            alt="kids-icon"
          />
          <h3 className="text-center font-12">Kids</h3>
        </a>
      </div>
      <div>
        <a href="../maths/maths.html">
          <img
            src="../assets/maths-icon.svg"
            className="testImg"
            alt="maths-icon"
          />
          <h3 className="text-center font-12">Maths</h3>
        </a>
      </div>
      <div>
        <a href="../abacus/abacus.html">
          <img
            src="../assets/abacus-icon.svg"
            className="testImg"
            alt="abacus-icon"
          />
          <h3 className="text-center font-12">Abacus</h3>
        </a>
      </div>
      <div>
        <img
          src="../assets/youtube-icon.svg"
          className="testImg"
          alt="youtube-icon"
        />
        <h3 className="text-center font-12">Youtube</h3>
      </div>
    </div>
  </section>
);

export default TestSkills;
