// src/utils/checkToken.js
import axiosInstance from "../axiosInstance";

export const checkTokenValidity = async () => {
  try {
    const response = await axiosInstance.get("/api/verify-token");
    return response.data.valid;
  } catch (error) {
    console.error("Error verifying token:", error);
    return false;
  }
};
