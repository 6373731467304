import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  uploadProfileImage,
  userData,
  getImage,
} from "../services/profileService";
// import "../css/hamburger.css"; // CSS file for styling
const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profileImage, setProfileImage] = useState(
    "../assets/navSlider/user.png"
  );
  const [imagePreview, setImagePreview] = useState(profileImage);
  const [username, setUsername] = useState("");
  const menuRef = useRef(null);
  const inputFileRef = useRef(null);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Close the menu when clicking outside
  useEffect(() => {
    userData()
      .then((data) => {
        if (data) {
          setProfileImage(data.profileImage);
          const image = getImage({ image: data.profileImage });
          setImagePreview(image);
          setUsername(data.firstName + " " + data.lastName);
        } else {
          console.log("No user data found");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user data:", error);
      });
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      uploadImage(file);
    }
  };
  const uploadImage = async (file) => {
    try {
      const data = await uploadProfileImage(file);
      setProfileImage(data.filePath);
      alert(data.message);
    } catch (error) {
      alert("Failed to upload profile image.");
    }
  };
  return (
    <>
      <div className="hamburgerMenu">
        <div className="hamburgerIcon" onClick={toggleMenu}>
          {!isOpen && <img src="../assets/hamburger.svg" alt="menu icon" />}
        </div>
      </div>
      {isOpen && <div className="mask" onClick={toggleMenu}></div>}
      <nav className={`slideNav ${isOpen ? "show" : ""}`} ref={menuRef}>
        <div className="slideUserBox">
          <img
            src={imagePreview}
            className="navUserImg"
            alt="user"
            onClick={() => inputFileRef.current.click()}
          />
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={inputFileRef}
            onChange={handleImageChange}
          />
          <h2 className="text-white font-18 weight-300">{username}</h2>
          {/* <p className="text-white font-14 weight-300">
              Membership ID: 0011477
            </p> */}
        </div>

        <div>
          <ul className="sliderUl">
            <li className="d-flex align-items-center">
              <figure className="slideFig">
                <img
                  src="../assets/navSlider/profileIcon.svg"
                  alt="profileIcon"
                />
              </figure>
              <Link to="/dashboard" className="slideLinks">
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <figure className="slideFig">
                <img src="../assets/navSlider/kids.png" alt="kids" />
              </figure>
              <Link to="/kids" className="slideLinks">
                <span>Kids</span>
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <figure className="slideFig">
                <img src="../assets/navSlider/maths.png" alt="maths" />
              </figure>
              <Link to="/maths" className="slideLinks">
                <span>Maths</span>
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <figure className="slideFig">
                <img src="../assets/navSlider/abacus.png" alt="abacus" />
              </figure>
              <Link to="/abacus" className="slideLinks">
                <span>Abacus</span>
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <figure className="slideFig">
                <img src="../assets/navSlider/logout.svg" alt="logout" />
              </figure>
              <Link to="/logout" className="slideLinks">
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default HamburgerMenu;
