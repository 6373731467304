import React from "react";
import "../css/index.css";

const RecentTransactions = () => (
  <section className="latestCompetitions padding-bottom">
    <div className="bgBox">
      <div className="container d-flex latestMain align-items-center justify-content-between">
        <h2 className="font-14 weight-500">Recent Transactions</h2>
        <button className="viewMore">View More</button>
      </div>
    </div>

    <div className="recentTrans container">
      <div className="col-1">
        <img
          src="../assets/transaction-icon.svg"
          className="round-icon"
          alt="transaction-icon"
          draggable="false"
        />
      </div>
      <div className="col-2">
        <h4 className="font-12">ID: 000124785877</h4>
        <p className="font-10">Withdraw 28th Dec, 2023. 4:15 AM</p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="dollor15 font-12">+ $15</p>
        </div>
      </div>
    </div>
    <div className="recentTrans container">
      <div className="col-1">
        <img
          src="../assets/transaction-icon.svg"
          className="round-icon"
          alt="transaction-icon"
          draggable="false"
        />
      </div>
      <div className="col-2">
        <h4 className="font-12">ID: 000124785866</h4>
        <p className="font-10">Withdraw 24th Dec, 2023. 1:15 PM</p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="dollor15 font-12">+ $15</p>
        </div>
      </div>
    </div>
  </section>
);

export default RecentTransactions;
