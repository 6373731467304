import React from "react";
import { Link, useLocation } from "react-router-dom"; // Use Link for internal navigation
import HamburgerMenu from "./HamburgerMenu"; // Adjust the path as needed
import "../css/index.css"; // Ensure this path is correct

const Header = ({ title }) => {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  return (
    <header className="headerSection">
      <nav className="topNav">
        <ul className="burgerLogo">
          <li className="logoList">
            {isDashboard ? (
              <img src="../assets/logo.svg" alt="logo" />
            ) : (
              <Link to="/dashboard">
                <img src="../assets/logo.svg" alt="logo" />
              </Link>
            )}
          </li>
          <li className="headTitle font-22">{title}</li>
          <li>
            {" "}
            <HamburgerMenu />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
