// src/components/PrivateRoute.jsx
import React, { useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";
import { checkTokenValidity } from "../utils/checkToken";
import { refreshToken } from "../utils/refreshToken";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    const verifyAndRefreshToken = async () => {
      let valid = await checkTokenValidity();
      // if (!valid) {
      //   valid = await refreshToken(); // Try to refresh token
      // }
      setIsValid(valid);
    };
    verifyAndRefreshToken();
  }, []);

  if (isValid === null) {
    // Optionally, show a loader or something while checking
    return <div>Loading...</div>;
  }
  if (!isValid) {
    localStorage.removeItem("token");
  }
  return isValid ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
