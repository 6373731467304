// apiService.js
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3333";
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const sendPasswordResetLink = async (email) => {
  return api.post("/password/reset", { email });
};

export const resetPassword = async (token, newPassword) => {
  return api.post(`/password/reset/${token}`, { newPassword });
};
