import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3333";

export const fetchCountries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/countries`);
    return response.data;
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw error;
  }
};

export const fetchStates = async (countryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/states?country_id=${countryId}`
    );
    return response.data.states;
  } catch (error) {
    console.error("Error fetching states:", error);
    throw error;
  }
};

export const fetchCities = async (stateId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/cities?state_id=${stateId}`
    );
    return response.data.cities;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};
