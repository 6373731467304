import React, { useState, useEffect } from "react";
import {
  fetchCountries,
  fetchStates,
  fetchCities,
} from "../services/addressService";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { registerUser } from "../services/authService";
import "../css/login.css";
import "../css/common-style.css";
import Verification from "../components/Verification";
import { COUNTRY_ADDRESS_POSTALS } from "../utils/postcode.ts";
import { checkEmailOrMobile } from "../services/profileService";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    school: "",
    address: "",
    country: "",
    state: "",
    dob: "",
    city: "",
    std_class: "",
    zipcode: "",
    password: "",
    confirmPassword: "",
  });
  const validateZipCode = (countryAbbrev, zipCode) => {
    const country = COUNTRY_ADDRESS_POSTALS.find(
      (c) => c.abbrev === countryAbbrev
    );
    if (!country) {
      throw new Error(`Country with abbreviation ${countryAbbrev} not found.`);
    }
    if (country.postal) {
      const postalRegex = new RegExp(country.postal);
      return postalRegex.test(zipCode);
    }
    return false; // If no postal regex is defined, assume invalid zip code
  };
  const handlePhoneChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      mobileNumber: value,
    }));
    if (!value) {
      return;
    }
    if (!isValidPhoneNumber(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: "Invalid mobile number",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: "",
      }));
    }
  };
  const [isVerified, setIsVerified] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    zipcode: "",
    email: "",
    mobileNumber: "",
    firstName: "",
    lastName: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const isVerified = localStorage.getItem("isVerified");
    if (isVerified === "true") {
      setIsVerified(true);
    }
    (async () => {
      try {
        const countriesData = await fetchCountries();
        setCountries(countriesData);
      } catch (error) {
        setMessage("Error fetching countries.");
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const letterOnlyRegex = /^[A-Za-z]+$/;

    if (name === "firstName" || name === "lastName") {
      if (!letterOnlyRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${
            name.charAt(0).toUpperCase() + name.slice(1)
          } can only contain letters.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
    switch (name) {
      case "country":
        handleCountryChange(value);
        break;
      case "state":
        handleStateChange(value);
        break;
      case "password":
        handlePasswordChange(value);
        break;
      case "confirmPassword":
        handleConfirmPasswordChange(value);
        break;
      case "zipcode":
        handleZipcodeChange(value);
        break;
      case "email":
        handleEmailChange(value);
        break;
      default:
        break;
    }
  };
  const handleCountryChange = (value) => {
    fetchStates(value)
      .then((statesData) => setStates(statesData))
      .catch(() => setMessage("Error fetching states."));
    setCities([]);
  };

  const handleStateChange = (value) => {
    fetchCities(value)
      .then((citiesData) => setCities(citiesData))
      .catch(() => setMessage("Error fetching cities."));
  };
  const handlePasswordChange = (value) => {
    const passwordErrors = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: passwordErrors }));
  };
  const handleEmailChange = (value) => {
    if (!validateEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };
  const handleConfirmPasswordChange = (value) => {
    if (value !== formData.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleZipcodeChange = (value) => {
    if (formData.country) {
      const countryId =
        typeof formData.country === "string"
          ? parseInt(formData.country, 10)
          : formData.country;
      const selected = countries.find((country) => country.id === countryId);
      const isValidZip = validateZipCode(selected.iso2, value);
      if (!isValidZip) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          zipcode: "Invalid zip code for the selected country.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, zipcode: "" }));
      }
    }
  };
  const validatePassword = (password) => {
    const passwordErrors = [];
    if (password.length < 8) {
      passwordErrors.push("Password must be at least 8 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one uppercase letter."
      );
    }
    if (!/[a-z]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one lowercase letter."
      );
    }
    if (!/[0-9]/.test(password)) {
      passwordErrors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      passwordErrors.push(
        "Password must contain at least one special character."
      );
    }
    return passwordErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);
    try {
      await registerUser(formData);
      localStorage.setItem("email", formData.email);
      setMessage("Registration successful!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        school: "",
        address: "",
        country: "",
        state: "",
        city: "",
        dob: "",
        std_class: "",
        zipcode: "",
        password: "",
        confirmPassword: "",
      });
      setStates([]);
      setCities([]);
      localStorage.setItem("isVerified", "true");
      setIsVerified(true);
    } catch (error) {
      setMessage("Registration failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleFocusChange = async (field) => {
    let newErrors = { ...errors };
    try {
      if (field === "email") {
        const response = await checkEmailOrMobile({
          email: formData.email,
        });
        if (response.emailExists) {
          newErrors.email = "Email already exists.";
        } else {
          newErrors.email = "";
        }
      } else if (field === "mobileNumber") {
        const response = await checkEmailOrMobile({
          mobileNumber: formData.mobileNumber,
        });
        if (response.mobileNumberExists) {
          newErrors.mobileNumber = "Mobile number already exists.";
        } else {
          newErrors.mobileNumber = "";
        }
      }
    } catch (error) {
      console.error("Error checking email or mobile number", error);
      if (field === "email") {
        newErrors.email = "Error checking email.";
      } else if (field === "mobileNumber") {
        newErrors.mobileNumber = "Error checking mobile number.";
      }
    }
    setErrors(newErrors);
  };
  const validateForm = () => {
    let valid = true;
    let newErrors = {
      password: "",
      confirmPassword: "",
      zipcode: "",
      email: "",
      mobileNumber: "",
      firstName: "",
      lastName: "",
    };
    const letterOnlyRegex = /^[A-Za-z]+$/;

    if (!letterOnlyRegex.test(formData.firstName)) {
      newErrors.firstName = "Firstname can only contain letters.";
      valid = false;
    }

    if (!letterOnlyRegex.test(formData.lastName)) {
      newErrors.lastName = "Lastname can only contain letters.";
      valid = false;
    }
    const passwordErrors = validatePassword(formData.password);
    if (passwordErrors.length > 0) {
      newErrors.password = passwordErrors;
      valid = false;
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.mobileNumber = "Passwords do not match.";
      valid = false;
    }
    if (!isValidPhoneNumber(formData.mobileNumber)) {
      newErrors.mobileNumber = "Invalid mobile number";
      valid = false;
    }
    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address.";
      valid = false;
    }
    if (!formData.zipcode) {
      newErrors.zipcode = "Zipcode is required.";
      valid = false;
    } else {
      const countryId =
        typeof formData.country === "string"
          ? parseInt(formData.country, 10)
          : formData.country;
      const selected = countries.find((country) => country.id === countryId);
      const isValidZip = validateZipCode(selected.iso2, formData.zipcode);
      if (!isValidZip) {
        newErrors.zipcode = "Invalid zip code for the selected country.";
        valid = false;
      }
    }

    try {
      const response = checkEmailOrMobile({
        email: formData.email,
        mobileNumber: formData.mobileNumber,
      });
      if (response.emailExists) {
        newErrors.email = "Email already exists.";
        valid = false;
      }
      if (response.mobileNumberExists) {
        newErrors.mobileNumber = "Mobile number already exists.";
        valid = false;
      }
    } catch (error) {
      console.error("Error checking email or mobile number", error);
      newErrors.email = "Error checking email or mobile number.";
      valid = false;
    }
    setErrors(newErrors);
    // Additional validation logic can be added here
    return valid;
  };
  return (
    <main className="regMain">
      <div className="wrapper">
        <section className="loginSection">
          <div className="logoContainer">
            <img
              src="/assets/login/login-main-logo.png" // Adjust the path as needed
              alt="login-main-logo"
              className="loginMainLogo"
            />
          </div>
          <div>
            <h2 className="text-center font-38 regHead weight-500">
              Registration
            </h2>
          </div>
          {!isVerified ? (
            <form className="container" onSubmit={handleSubmit}>
              {message && <div className="text-center">{message}</div>}
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                {errors.firstName && (
                  <p className="error">{errors.firstName}</p>
                )}
              </div>
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>
              <div className="text-center">
                <input
                  type="email"
                  className="loginInput"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={() => handleFocusChange("email")}
                  // onFocus={() => handleFocusChange("email")}
                  required
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="text-center">
                <PhoneInput
                  defaultCountry="IN"
                  className="loginInput"
                  international={true}
                  name="mobileNumber"
                  placeholder="Enter phone number"
                  value={formData.mobileNumber}
                  onChange={handlePhoneChange}
                  onBlur={() => handleFocusChange("mobileNumber")}
                  // onFocus={() => handleFocusChange("mobileNumber")}
                />
                {errors.mobileNumber && (
                  <p className="error">{errors.mobileNumber}</p>
                )}
              </div>

              <div className="text-center">
                <input
                  type="date"
                  className="loginInput"
                  name="dob"
                  placeholder="DOB"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
                {errors.dob && (
                  <span className="error-message">{errors.dob}</span>
                )}
              </div>
              <div className="text-center">
                <select
                  className="loginInput"
                  name="country"
                  value={formData.country}
                  onChange={(e) => {
                    handleChange(e);
                    fetchStates(e.target.value);
                  }}
                  required
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-center">
                <select
                  className="loginInput"
                  name="state"
                  value={formData.state}
                  onChange={(e) => {
                    handleChange(e);
                    fetchCities(e.target.value);
                  }}
                  required
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-center">
                <select
                  className="loginInput"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="school"
                  placeholder="School"
                  value={formData.school}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="std_class"
                  placeholder="Class"
                  value={formData.std_class}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-center">
                <input
                  type="text"
                  className="loginInput"
                  name="zipcode"
                  placeholder="Zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                  required
                />
                {errors.zipcode && <p className="error">{errors.zipcode}</p>}
              </div>
              <div className="text-center">
                <input
                  type="password"
                  className="loginInput"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                {errors.password.length > 0 && (
                  <ul className="error-message-ul">
                    {errors.password.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="text-center">
                <input
                  type="password"
                  className="loginInput"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                {errors.confirmPassword && (
                  <p className="error">{errors.confirmPassword}</p>
                )}
              </div>
              <div className="text-center btnBox">
                <button
                  type="submit"
                  className="loginBtn signIn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
                <p className="signPara font-14">
                  If you have an account?{" "}
                  <a href="/login" className="signInLink">
                    Sign in
                  </a>{" "}
                  in seconds.
                </p>
              </div>
            </form>
          ) : (
            <Verification />
          )}
          <footer className="loginFooter footerStyle">
            <div>
              <p className="text-center text-white font-12 weight-400">
                Skillpundit Career Private Limited
              </p>
            </div>
          </footer>
          <figure className="topEffect">
            <img src="/assets/login/topEffect.svg" alt="topEffect" />
          </figure>
          <figure className="bottomEffect">
            <img src="/assets/login/bottomEffect.svg" alt="bottomEffect" />
          </figure>
        </section>
      </div>
    </main>
  );
};

export default RegisterPage;
