import React from "react";
import Header from "../components/Header";
import UserBalanceSection from "../components/UserBalanceSection";
import TestSkills from "../components/TestSkills";
import LatestCompetitions from "../components/LatestCompetitions";
import Toppers from "../components/Toppers";
import RecentTransactions from "../components/RecentTransactions";
import Footer from "../components/Footer";

const Dashboard = () => (
  <div className="wrapper dashboardSection">
    <Header title="Dashboard" />
    <UserBalanceSection />
    <TestSkills />
    <LatestCompetitions />
    <Toppers />
    <RecentTransactions />
    <Footer />
  </div>
);

export default Dashboard;
