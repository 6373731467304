import React, { useState, useRef, useEffect } from "react";
import "../css/index.css";
import { userData, getImage } from "../services/profileService";
const UserBalanceSection = () => {
  const [profileImage, setProfileImage] = useState(
    "../assets/navSlider/user.png"
  );
  const [imagePreview, setImagePreview] = useState(profileImage);
  const [username, setUsername] = useState("");
  useEffect(() => {
    userData()
      .then((data) => {
        if (data) {
          setProfileImage(data.profileImage);
          const image = getImage({ image: data.profileImage });
          setImagePreview(image);
          setUsername(data.firstName + " " + data.lastName);
        } else {
          console.log("No user data found");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user data:", error);
      });
  }, []);
  return (
    <section className="userBalanceSection container padding-top">
      <div>
        <div className=" d-flex userBox ">
          <div>
            <img src={imagePreview} className="userIconBox" alt="user-icon" />
          </div>
          <div className="userContent">
            <h2 className="font-22 weight-400 userName">{username}</h2>
            <p className="font-12 weight-400 membership">
              {/* Membership ID: 0011477 */}
            </p>
          </div>
        </div>
        <div className="balancemainBox d-flex">
          <div className="balanceBox d-flex">
            <div className="height-100">
              <img
                src="../assets/wallet-icon.svg"
                className="walletImg"
                alt="wallet-icon"
              />
            </div>
            <div>
              <h3 className="font-15 weight-400">Balance</h3>
              <p className="font-12">December 28, 2023.</p>
              {/*<p className="font-38 color-primary weight-500">$21188.00</p>*/}
            </div>
          </div>
          <div className="withdrawBox text-center">
            <button className="withdrawBtn">Withdraw</button>
            <p className="font-12 points250">
              Points: <b>250</b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserBalanceSection;
