import React from "react";
import "../css/index.css";

const Toppers = () => (
  <section>
    <div className="bgBox">
      <div className="container d-flex latestMain align-items-center justify-content-between">
        <h2 className="font-14 weight-500">Toppers</h2>
        <button className="viewMore">View More</button>
      </div>
    </div>
    <div className="toppersProd container">
      <div className="col-1">
        <img src="../assets/girl.png" alt="girl" />
      </div>
      <div className="col-2">
        <h4 className="font-12">Aadhya Sharmila</h4>
        <p className="font-10">
          Class: <b>4th,</b>
        </p>
        <p className="font-10">
          School: <b>Lakshya International School, Vizag</b>
        </p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="font-10 rank">Rank</p>
          <p className="font-12 oneBefore">1</p>
        </div>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="font-10 points">Points</p>
          <p className="twenty50 font-12">255</p>
        </div>
      </div>
    </div>
    <div className="toppersProd container">
      <div className="col-1">
        <img src="../assets/boy.png" alt="boy" />
      </div>
      <div className="col-2">
        <h4 className="font-12">Aadhya Sharmila</h4>
        <p className="font-10">
          Class: <b>4th,</b>
        </p>
        <p className="font-10">
          School:<b>Lakshya International School, Vizag</b>
        </p>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="font-10 rank">Rank</p>
          <p className="font-12 oneBefore">2</p>
        </div>
      </div>
      <div className="col-3">
        <div className="buyBox">
          <p className="font-10 points">Points</p>
          <p className="twenty50 font-12">250</p>
        </div>
      </div>
    </div>
  </section>
);

export default Toppers;
