import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/common-style.css";
import "../css/login.css";
import { verifyOtp, resendOtp } from "../services/authService";
const Verification = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState(""); // Store the email here
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the email from localStorage
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^\d?$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join(""); // Join OTP array to form a single string

    try {
      const response = await verifyOtp(otpCode, email);
      if (response.data.status) {
        localStorage.setItem("token", response.data.token.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.removeItem("email");
        localStorage.removeItem("isVerified");
        navigate("/dashboard"); // Redirect or handle success response
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <form className="container" onSubmit={handleSubmit}>
      <div className="otpWrapper text-center d-flex justify-content-evenly">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            className="verifyInput"
            value={value}
            onChange={(e) => handleOtpChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength="1"
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
      </div>

      <div className="container">
        <div className="text-center btnBox">
          <button type="submit" className="verifyBtn">
            Verify
          </button>
          <p className="resend">
            <a
              href="#"
              onClick={() => {
                resendOtp(email);
              }}
            >
              Resend
            </a>
          </p>
        </div>
      </div>
    </form>
  );
};

export default Verification;
