import React from "react";
import { Link } from "react-router-dom";
import "../css/index.css";

const Footer = () => (
  <nav id="mainNav" className="footer-nav">
    <ul className="navUl container">
      <li className="text-center button-size">
        <Link to="/">
          <img
            src="../assets/home-button.svg"
            className="menuBtn"
            alt="home-button"
          />
          <p className="text-white">Home</p>
        </Link>
      </li>
      <li className="text-center button-size">
        <Link to="/myexams">
          <img
            src="../assets/myexams-button.svg"
            className="menuBtn"
            alt="myexams-button"
          />
          <p className="text-white">My Exams</p>
        </Link>
      </li>
      <li className="text-center button-size">
        <Link to="/mypoints">
          <img
            src="../assets/myPoints-button.svg"
            className="menuBtn"
            alt="myPoints-button"
          />
          <p className="text-white">My Points</p>
        </Link>
      </li>
      <li className="text-center button-size">
        <Link to="/awards">
          <img
            src="../assets/certificate-icon.svg"
            className="menuBtn"
            alt="certificate-icon"
          />
          <p className="text-white">Awards</p>
        </Link>
      </li>
      <li className="text-center button-size">
        <Link to="/offers">
          <img
            src="../assets/offers-button.svg"
            className="menuBtn"
            alt="offers-button"
          />
          <p className="text-white">Offers</p>
        </Link>
      </li>
    </ul>
  </nav>
);

export default Footer;
